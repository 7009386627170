import {
  GOOGLE_METRICS,
  META_METRICS,
  MISC_METRICS,
  SCORE_METRICS,
  TIKTOK_METRICS,
} from "~/shared/config";
import {
  formatFractionalPercentage,
  formatInteger,
  formatNumber,
  formatPercentage,
  formatSeconds,
  getFormatCurrency,
  groupBy,
} from "~/shared/utils";
import {
  MetaMetric,
  TiktokMetric,
  tiktokMetricToFormatValueFunction,
  metaMetricToFormatValueFunction,
  metricToPercentageUpColor,
  ValueFormatter,
  googleMetricToFormatValueFunction,
  GoogleMetric,
  CustomMetricRule,
  CustomMetricRuleResponse,
  CUSTOM_METRIC_NAME_PREFIX,
  AggregateFunction,
  metaMetricToAggregateFunction,
  googleMetricToAggregateFunction,
  tiktokMetricToAggregateFunction,
  MISC_METRIC_TO_FORMATTER,
  MISC_METRIC_TO_PERCENTAGE_UP_COLOR,
} from "~/types/Metrics";
import {
  Config,
  ConfigWithDescriptionMaybe,
  ConfigWithGroupAndDescriptionMaybe,
  Provider,
} from "~/types/shared";

export const useMetrics = () => {
  const getMetaMetrics = () => {
    const { hasFeature } = useFeatureFlags();
    return useGetEnabledConfig(META_METRICS).filter((m) =>
      typeof m.featureFlag === "string" ? hasFeature(m.featureFlag) : true,
    );
  };

  const getTiktokMetrics = () => {
    const { hasFeature } = useFeatureFlags();
    return useGetEnabledConfig(TIKTOK_METRICS).filter((m) =>
      typeof m.featureFlag === "string" ? hasFeature(m.featureFlag) : true,
    );
  };

  const getGoogleMetrics = () => {
    const { hasFeature } = useFeatureFlags();
    return useGetEnabledConfig(GOOGLE_METRICS).filter((m) =>
      typeof m.featureFlag === "string" ? hasFeature(m.featureFlag) : true,
    );
  };

  const getScoreMetrics = () => {
    const { hasFeature } = useFeatureFlags();
    return useGetEnabledConfig(SCORE_METRICS).filter((m) =>
      typeof m.featureFlag === "string" ? hasFeature(m.featureFlag) : true,
    );
  };

  const getMiscMetrics = () => {
    const { hasFeature } = useFeatureFlags();
    return useGetEnabledConfig(MISC_METRICS).filter((m) =>
      typeof m.featureFlag === "string" ? hasFeature(m.featureFlag) : true,
    );
  };

  const isPlatformAgnosticMetric = (metric: string) => {
    const scoreMetrics = getScoreMetrics();
    const miscMetrics = getMiscMetrics();
    return (
      scoreMetrics.some((m) => m.id === metric) ||
      miscMetrics.some((m) => m.id === metric)
    );
  };

  const metricToDisplayName = (
    metric: string,
    providers: Provider[],
    options?: Partial<{
      customConversionNames?: Record<string, string>;
      customMetricRules?: CustomMetricRule[];
    }>,
  ) => {
    const opts = options || {};
    const metricConfig: Array<Config<string>> = [];

    const conversionMetricMaybe = conversionMetricToDisplayName(
      metric,
      opts.customConversionNames,
    );

    if (typeof conversionMetricMaybe === "string") {
      return conversionMetricMaybe;
    }

    const customMetricNames = customMetricRulesToCustomMetricNames(
      opts.customMetricRules || [],
    );
    const customMetricMaybe = conversionMetricToDisplayName(
      metric,
      customMetricNames,
    );

    if (typeof customMetricMaybe === "string") {
      return customMetricMaybe;
    }

    const scoreMetricMaybe = scoreMetricToDisplayName(metric);

    if (typeof scoreMetricMaybe === "string") {
      return scoreMetricMaybe;
    }

    const miscMetricMaybe = miscMetricToDisplayName(metric);

    if (typeof miscMetricMaybe === "string") {
      return miscMetricMaybe;
    }

    providers.forEach((provider) =>
      metricConfig.push(...providerToMetricConfig(provider)),
    );
    const idx = metricConfig.findIndex((config) => config.id === metric);

    if (idx < 0) {
      return metric;
    }

    return metricConfig[idx].displayName;
  };

  const metricToTooltip = (
    metric: string,
    provider: Provider,
    options?: Partial<{
      customConversionNames?: Record<string, string>;
      customMetricRules?: CustomMetricRule[];
    }>,
  ) => {
    const opts = options || {};
    const metricDisplayName = metricToDisplayName(metric, [provider], opts);

    const conversionMetricMaybe = conversionMetricToDisplayName(
      metric,
      opts.customConversionNames,
    );

    if (typeof conversionMetricMaybe === "string") {
      return metricDisplayName;
    }

    const customMetricNames = customMetricRulesToCustomMetricNames(
      opts.customMetricRules || [],
    );
    const customMetricMaybe = conversionMetricToDisplayName(
      metric,
      customMetricNames,
    );

    if (typeof customMetricMaybe === "string") {
      return metricDisplayName;
    }

    const scoreMetricMaybe = scoreMetricToTooltip(metric);

    if (typeof scoreMetricMaybe === "string") {
      return scoreMetricMaybe;
    }

    const miscMetricMaybe = miscMetricToTooltip(metric);

    if (typeof miscMetricMaybe === "string") {
      return miscMetricMaybe;
    }

    const metricConfig = [...providerToMetricConfig(provider)];
    const idx = metricConfig.findIndex((config) => config.id === metric);

    if (idx < 0) {
      return metricDisplayName;
    }

    return metricConfig[idx].description != null &&
      metricConfig[idx].description !== ""
      ? metricConfig[idx].description
      : metricDisplayName;
  };

  const customMetricRulesToCustomMetricNames = (
    customMetricRules: CustomMetricRule[],
  ): Record<string, string> => {
    return Object.fromEntries(
      customMetricRules.map((rule) => [
        rule.metricName,
        rule.metricDisplayName,
      ]),
    );
  };

  const conversionMetricToDisplayName = (
    conversionMetric: string,
    customConversionNames: Record<string, string> = {},
  ) => {
    return conversionMetric in customConversionNames
      ? customConversionNames[conversionMetric]
      : null;
  };

  const scoreMetricToDisplayName = (scoreMetric: string) => {
    const scoreMetrics = getScoreMetrics();
    const idx = scoreMetrics.findIndex((config) => config.id === scoreMetric);
    return idx < 0 ? null : scoreMetrics[idx].displayName;
  };

  const scoreMetricToTooltip = (scoreMetric: string) => {
    const scoreMetrics = getScoreMetrics();
    const idx = scoreMetrics.findIndex((config) => config.id === scoreMetric);
    return idx < 0 ? null : scoreMetrics[idx].description;
  };

  const miscMetricToDisplayName = (miscMetric: string) => {
    const miscMetrics = getMiscMetrics();
    const idx = miscMetrics.findIndex((config) => config.id === miscMetric);
    return idx < 0 ? null : miscMetrics[idx].displayName;
  };

  const miscMetricToTooltip = (miscMetric: string) => {
    const miscMetrics = getMiscMetrics();
    const idx = miscMetrics.findIndex((config) => config.id === miscMetric);
    return idx < 0 ? null : miscMetrics[idx].description;
  };

  const providerToMetricConfig = (
    provider: Provider,
  ): ConfigWithGroupAndDescriptionMaybe<string>[] => {
    switch (provider) {
      case Provider.TIKTOK:
        return getTiktokMetrics();
      case Provider.GOOGLE:
        return getGoogleMetrics();
      default:
        return getMetaMetrics();
    }
  };

  const getProviderDefaultMetric = (provider: Provider) => {
    switch (provider) {
      case Provider.TIKTOK:
        return TiktokMetric.clicks;
      case Provider.GOOGLE:
        return GoogleMetric.clicks;
      default:
        return MetaMetric.clicks;
    }
  };

  const getMetricOptions = (
    provider: Provider[] | Provider,
    options?: Partial<{
      searchQuery?: string;
      customConversionNames?: Record<string, string>;
      customMetricRules?: CustomMetricRule[];
      prefixImgs?: boolean;
      prefixProvider?: boolean;
      showOwnRules?: boolean;
      showThirdPartyRules?: boolean;
      showScoreMetrics?: boolean;
      showMiscMetrics?: boolean;
      showUnselectOption?: boolean;
    }>,
  ) => {
    const opts = options || {};
    const _searchQuery = opts.searchQuery ? opts.searchQuery.toLowerCase() : "";
    const providers = Array.isArray(provider) ? [...provider] : [provider];
    const { getProviderLogos } = useConnection();
    const metricsOptions = providers
      .map((provider) => {
        const metricConfig = providerToMetricConfig(provider).filter((metric) =>
          metric.displayName.toLowerCase().includes(_searchQuery),
        );
        const providerLogos = getProviderLogos(provider);
        return {
          metricConfig,
          providerLogos,
          provider,
        };
      })
      .flatMap(({ metricConfig, providerLogos, provider }) =>
        metricConfig.map((metric) => ({
          value:
            opts.prefixProvider === true
              ? `${provider}:${metric.id}`
              : metric.id,
          displayValue: metric.displayName,
          group: metric.group,
          tooltipMaybe: metric.description,
          prefixImgs: opts.prefixImgs === true ? providerLogos : undefined,
          customMetricId: null as number | null,
        })),
      );
    const customConversionNames = opts.customConversionNames || {};
    const { getCustomConversionToProviderMap } = useAdAccounts();
    const customConversionToProviderMap = getCustomConversionToProviderMap();
    const customConversionOptions = Object.entries(customConversionNames)
      .filter(([_, displayName]) =>
        displayName.toLowerCase().includes(_searchQuery),
      )
      .map(([metric, displayName]) => {
        // Dont cal getCustomConversionToProviderMap or similar reactive function here as this causes a cpu spike
        const providerMaybe = customConversionToProviderMap[metric];
        const providerLogos = providerMaybe
          ? getProviderLogos(providerMaybe)
          : undefined;
        return {
          value:
            opts.prefixProvider === true
              ? `${providerMaybe}:${metric}`
              : metric,
          displayValue: displayName,
          group: "Custom Conversion",
          tooltipMaybe: "",
          prefixImgs: opts.prefixImgs === true ? providerLogos : undefined,
          customMetricId: null as number | null,
        };
      });
    const customMetricRules = opts.customMetricRules || [];
    const ownRules = customMetricRules.filter(
      (rule) => rule.customMetricRuleId != null && rule.customMetricRuleId > 0,
    );
    const thirdPartyRules = customMetricRules.filter(
      (rule) => rule.customMetricRuleId == null,
    );
    const thirdPartyOptions = getCustomMetricOptions({
      groupName: "3rd Party Metrics",
      rules: thirdPartyRules,
      prefixImgs: opts.prefixImgs,
      prefixProvider: opts.prefixProvider,
      searchQuery: _searchQuery,
    });
    const ownRuleOptions = getCustomMetricOptions({
      groupName: "Custom Metrics",
      rules: ownRules,
      prefixImgs: opts.prefixImgs,
      prefixProvider: opts.prefixProvider,
      searchQuery: _searchQuery,
    });
    const scoreMetricOptions = getScoreMetricOptions({
      searchQuery: _searchQuery,
      prefixImgs: opts.prefixImgs,
    });
    const miscMetricOptions = getMiscMetricOptions({
      searchQuery: _searchQuery,
      prefixImgs: opts.prefixImgs,
    });
    const unselectOption = opts.showUnselectOption
      ? [
          {
            value: null,
            displayValue: "Unselect",
            group: "Unselect",
            customMetricId: null,
          },
        ]
      : [];
    const allOptions = [
      ...unselectOption,
      ...metricsOptions,
      ...customConversionOptions,
    ];
    if (opts.showScoreMetrics !== false) {
      allOptions.push(...scoreMetricOptions);
    }
    if (opts.showMiscMetrics !== false) {
      allOptions.push(...miscMetricOptions);
    }
    if (opts.showThirdPartyRules !== false) {
      allOptions.push(...thirdPartyOptions);
    }
    if (opts.showOwnRules !== false) {
      allOptions.push(...ownRuleOptions);
    }
    return groupBy(allOptions, "group");
  };

  const getScoreMetricOptions = (dto: {
    searchQuery: string;
    prefixImgs: boolean | undefined;
  }) => {
    const scoreMetrics = getScoreMetrics();
    return getPlatformAgnosticMetricOptions({
      metrics: scoreMetrics,
      groupName: "Score Metrics",
      searchQuery: dto.searchQuery,
      prefixImgs: dto.prefixImgs,
    });
  };

  const getMiscMetricOptions = (dto: {
    searchQuery: string;
    prefixImgs: boolean | undefined;
  }) => {
    const miscMetrics = getMiscMetrics();
    return getPlatformAgnosticMetricOptions({
      metrics: miscMetrics,
      groupName: "Other Metrics",
      searchQuery: dto.searchQuery,
      prefixImgs: dto.prefixImgs,
    });
  };

  const getPlatformAgnosticMetricOptions = <T extends string>(dto: {
    metrics: ConfigWithDescriptionMaybe<T>[];
    groupName: string;
    searchQuery: string;
    prefixImgs: boolean | undefined;
  }) => {
    return dto.metrics
      .filter((metric) =>
        metric.displayName.toLowerCase().includes(dto.searchQuery),
      )
      .map((metric) => {
        return {
          value: metric.id,
          displayValue: metric.displayName,
          group: dto.groupName,
          tooltipMaybe: metric.description,
          prefixImgs: dto.prefixImgs === true ? ["/img/datads.png"] : undefined,
          customMetricId: null,
        };
      });
  };

  const getCustomMetricOptions = (dto: {
    rules: CustomMetricRule[];
    searchQuery: string;
    prefixProvider: boolean | undefined;
    prefixImgs: boolean | undefined;
    groupName: string;
  }) => {
    const { getProviderLogos } = useConnection();
    const getTooltip = (metricName: string) =>
      dto.rules.find((rule) => rule.metricName === metricName)?.description ||
      "";
    const getCustomMetricId = (metricName: string) =>
      dto.rules.find((rule) => rule.metricName === metricName)
        ?.customMetricRuleId || null;
    const customMetricNames = customMetricRulesToCustomMetricNames(dto.rules);
    return Object.entries(customMetricNames)
      .filter(([_, displayName]) =>
        displayName.toLowerCase().includes(dto.searchQuery),
      )
      .map(([metric, displayName]) => {
        const clientStore = useClientStore();
        const providerMaybe = clientStore.providerOfCustomMetric(metric);
        const providerLogos = providerMaybe
          ? getProviderLogos(providerMaybe)
          : undefined;
        return {
          value:
            dto.prefixProvider === true ? `${providerMaybe}:${metric}` : metric,
          displayValue: displayName,
          group: dto.groupName,
          tooltipMaybe: getTooltip(metric),
          prefixImgs: dto.prefixImgs === true ? providerLogos : undefined,
          customMetricId: getCustomMetricId(metric),
        };
      });
  };

  const getValueFormatterType = (
    metric: string,
    provider: Provider,
    customMetricRules?: CustomMetricRule[],
  ): ValueFormatter => {
    const ruleMaybe = customMetricRules?.find(
      (rule) => rule.metricName === metric,
    );

    if (ruleMaybe) {
      return ruleMaybe.formatter;
    }

    const scoreMetricMaybe = SCORE_METRICS.find((m) => m.id === metric);

    if (scoreMetricMaybe) {
      return "integer";
    }

    const miscMetricMaybe = MISC_METRICS.find((m) => m.id === metric);

    if (miscMetricMaybe) {
      return MISC_METRIC_TO_FORMATTER[miscMetricMaybe.id];
    }

    switch (provider) {
      case Provider.TIKTOK:
        return tiktokMetricToFormatValueFunction[metric as TiktokMetric];
      case Provider.GOOGLE:
        return googleMetricToFormatValueFunction[metric as GoogleMetric];
      default:
        return metaMetricToFormatValueFunction[metric as MetaMetric];
    }
  };

  const getValueFormatter = (
    metric: string,
    provider: Provider,
    currency = "EUR",
    customMetricRules?: CustomMetricRule[],
  ) => {
    const formatter = getValueFormatterType(
      metric,
      provider,
      customMetricRules,
    );
    return getValueFormatterFunc(formatter, currency);
  };

  const getValueFormatterFunc = (
    formatter: ValueFormatter,
    currency: string,
  ): ((...args: any[]) => string) => {
    switch (formatter) {
      case "currency":
        return getFormatCurrency(currency);
      case "integer":
        return formatInteger;
      case "number":
        return formatNumber;
      case "percentage":
      case "decimalPercentage":
        return formatPercentage;
      case "decimalAsPercentage":
        return formatFractionalPercentage;
      case "seconds":
        return formatSeconds;
      default:
        return formatNumber;
    }
  };

  const getAggregateFunction = (dto: {
    metricName: string;
    provider: Provider;
    customMetricRules: CustomMetricRule[];
    customConversionNames: Record<string, string>;
  }): AggregateFunction => {
    const ruleMaybe = dto.customMetricRules.find(
      (rule) => rule.metricName === dto.metricName,
    );
    if (ruleMaybe) {
      return ruleMaybe.aggregateFunction;
    }
    const customConversionMaybe = dto.customConversionNames[dto.metricName];
    if (customConversionMaybe) {
      return AggregateFunction.SUM;
    }
    const scoreMetricMaybe = SCORE_METRICS.find((m) => m.id === dto.metricName);
    if (scoreMetricMaybe) {
      return AggregateFunction.AVG;
    }
    switch (dto.provider) {
      case Provider.TIKTOK:
        return tiktokMetricToAggregateFunction[dto.metricName as TiktokMetric];
      case Provider.GOOGLE:
        return googleMetricToAggregateFunction[dto.metricName as GoogleMetric];
      default:
        return metaMetricToAggregateFunction[dto.metricName as MetaMetric];
    }
  };

  const getPercentUpColor = (dto: {
    metricName: string;
    customMetricRules: CustomMetricRule[];
    customConversionNames: Record<string, string>;
  }): "green" | "red" => {
    const ruleMaybe = dto.customMetricRules.find(
      (rule) => rule.metricName === dto.metricName,
    );
    if (ruleMaybe) {
      return ruleMaybe.sort === "asc" ? "red" : "green";
    }
    const customConversionMaybe = dto.customConversionNames[dto.metricName];
    if (customConversionMaybe) {
      return "green";
    }
    const scoreMetricMaybe = SCORE_METRICS.find((m) => m.id === dto.metricName);
    if (scoreMetricMaybe) {
      return "green";
    }
    const miscMetricMaybe = MISC_METRICS.find((m) => m.id === dto.metricName);
    if (miscMetricMaybe) {
      return MISC_METRIC_TO_PERCENTAGE_UP_COLOR[miscMetricMaybe.id];
    }
    return metricToPercentageUpColor[dto.metricName as MetaMetric];
  };

  const getPercent = (
    curValue: number | undefined | null,
    compareValue: number | undefined | null,
  ) => {
    if (curValue == null || compareValue == null) {
      return "?";
    }

    const difference = curValue - compareValue;
    const fraction = compareValue > 0 ? difference / compareValue : NaN;
    const percentage = formatPercentage(fraction, 0);

    if (isNaN(fraction)) {
      return "?";
    }

    return fraction >= 0.005
      ? "↑ " + percentage
      : fraction <= -0.005
        ? "↓ " + percentage
        : "→ " + percentage;
  };

  const getPercentColor = (
    dto: {
      percentageChange: string | undefined | null;
      metricName: string;
      customMetricRules: CustomMetricRule[];
      customConversionNames: Record<string, string>;
    },
    percentUpColor = getPercentUpColor,
  ): "neutral" | "green" | "red" => {
    if (dto.percentageChange == null || dto.percentageChange[0] === "?") {
      return "neutral";
    }

    let color: "neutral" | "green" | "red" = "neutral";

    if (dto.percentageChange[0] === "↑") {
      color = percentUpColor(dto);
    } else if (dto.percentageChange[0] === "↓") {
      color = percentUpColor(dto) === "green" ? "red" : "green";
    } else {
      color = "neutral";
    }

    return color;
  };

  const getDefaultMetric = (provider: Provider) => {
    switch (provider) {
      case Provider.TIKTOK:
        return TiktokMetric.clicks;
      case Provider.GOOGLE:
        return GoogleMetric.clicks;
      default:
        return MetaMetric.clicks;
    }
  };

  const getMappedCustomMetricRules = (
    rules: Array<CustomMetricRuleResponse>,
  ): Array<CustomMetricRule> => {
    const mappedRules = rules.map((rule) => ({
      ...rule,
      customMetricRuleId: rule.id,
      metricName: titleToMetricName(rule.title, rule.id),
      metricDisplayName: rule.title,
      aggregateFunction: AggregateFunction.EXPRESSION,
      numerator: "",
      denominator: "",
      weightedBy: "",
    }));

    mappedRules.sort((a, b) => a.customMetricRuleId - b.customMetricRuleId);

    return mappedRules;
  };

  const titleToMetricName = (metricTitle: string | null, ruleId: number) => {
    if (metricTitle == null || metricTitle === "") {
      return "";
    }
    return (
      CUSTOM_METRIC_NAME_PREFIX +
      metricTitle
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .replace(/\s/g, "_")
        .toLowerCase() +
      ruleId
    );
  };

  const secondaryMetricsToPrimaryMetric = (
    secondaryMetrics: string[],
    provider: Provider,
  ) => {
    if (secondaryMetrics.length === 0) {
      return getDefaultMetric(provider);
    }
    return secondaryMetrics[0];
  };

  const getAvg = (dto: {
    metricName: string;
    provider: Provider;
    customMetricRules: CustomMetricRule[];
    customConversionNames: Record<string, string>;
    value: number;
    total: number;
  }) => {
    const aggregateFunction = getAggregateFunction(dto);
    if (aggregateFunction === AggregateFunction.SUM) {
      return dto.total > 0 ? dto.value / dto.total : 0;
    }
    return dto.value;
  };

  const getProviderOfMetric = (dto: {
    metric: string;
    providers: Provider[];
    customMetricRules: CustomMetricRule[];
    getProviderOfCustomConversion: (convId: string) => Provider | null;
  }) => {
    const {
      metric,
      providers,
      customMetricRules,
      getProviderOfCustomConversion,
    } = dto;
    const parts = metric.split(":");
    if (parts.length > 1) {
      return parts[0] as Provider;
    }
    const providerMaybe =
      getProviderOfCustomConversion(metric) ||
      providerOfCustomMetric(metric, customMetricRules);
    if (providerMaybe != null) {
      return providerMaybe;
    }
    for (const provider of providers) {
      if (
        providerToMetricConfig(provider).some((config) => config.id === metric)
      ) {
        return provider;
      }
    }
    return null;
  };

  const providerOfCustomMetric = (
    customMetricId: string,
    customMetricRules: CustomMetricRule[],
  ) => {
    const rules = customMetricRules.filter(
      (rule) => rule.metricName === customMetricId,
    );
    if (rules.length === 0) {
      return null;
    }
    return rules[0].sourceProvider;
  };

  const getRawMetricName = (metric: string | null) => {
    if (metric == null) return "";
    const parts = metric.split(":");
    return parts.length > 1 ? parts[1] : metric;
  };

  const metricToProviderLogo = (dto: {
    metric: string | null;
    providers: Provider[];
    customMetricRules: CustomMetricRule[];
    getProviderOfCustomConversion: (convId: string) => Provider | null;
  }) => {
    const { getProviderLogo } = useConnection();
    if (dto.metric == null) {
      return "/img/datads.png";
    }
    const provider = getProviderOfMetric({
      metric: dto.metric,
      providers: dto.providers,
      customMetricRules: dto.customMetricRules,
      getProviderOfCustomConversion: dto.getProviderOfCustomConversion,
    });
    if (provider == null) {
      return "/img/datads.png";
    }
    return getProviderLogo(provider);
  };

  const areMetricsOnSameScale = (dto: {
    metric1: string;
    metric2: string;
    provider: Provider;
  }): boolean => {
    const { metric1, metric2, provider } = dto;

    // Get the formatters and aggregate functions for the metrics
    const { formatter: formatter1, aggregateFunction: agg1 } =
      getMetricProperties(metric1, provider);
    const { formatter: formatter2, aggregateFunction: agg2 } =
      getMetricProperties(metric2, provider);

    if (!formatter1 || !formatter2 || !agg1 || !agg2) {
      return false;
    }

    // Check if either metric is a cost-per-action metric
    const isCPA1 = agg1 === AggregateFunction.COST_PER_ACTION;
    const isCPA2 = agg2 === AggregateFunction.COST_PER_ACTION;

    // If one is CPA and the other isn't, they're not on the same scale
    if (isCPA1 !== isCPA2) {
      return false;
    }

    // Check if both are ratios/percentages
    const isRatio1 = isRatioMetric(formatter1, agg1);
    const isRatio2 = isRatioMetric(formatter2, agg2);

    // If one is a ratio and the other isn't, they're not on the same scale
    if (isRatio1 !== isRatio2) {
      return false;
    }

    // For raw numbers/integers, they should be considered on different scales
    // unless they're both exactly the same formatter
    if (formatter1 === "integer" || formatter1 === "number") {
      return formatter1 === formatter2;
    }

    // For everything else, check if they have the same formatter
    return formatter1 === formatter2;
  };

  const getMetricProperties = (
    metric: string,
    provider: Provider,
  ): {
    formatter: ValueFormatter | null;
    aggregateFunction: AggregateFunction | null;
  } => {
    switch (provider) {
      case Provider.META:
        return {
          formatter:
            metaMetricToFormatValueFunction[metric as MetaMetric] || null,
          aggregateFunction:
            metaMetricToAggregateFunction[metric as MetaMetric] || null,
        };
      case Provider.TIKTOK:
        return {
          formatter:
            tiktokMetricToFormatValueFunction[metric as TiktokMetric] || null,
          aggregateFunction:
            tiktokMetricToAggregateFunction[metric as TiktokMetric] || null,
        };
      case Provider.GOOGLE:
        return {
          formatter:
            googleMetricToFormatValueFunction[metric as GoogleMetric] || null,
          aggregateFunction:
            googleMetricToAggregateFunction[metric as GoogleMetric] || null,
        };
      default:
        return { formatter: null, aggregateFunction: null };
    }
  };

  const isRatioMetric = (
    formatter: ValueFormatter,
    aggregateFunction: AggregateFunction,
  ): boolean => {
    return (
      formatter === "percentage" ||
      formatter === "decimalPercentage" ||
      formatter === "decimalAsPercentage" ||
      aggregateFunction === AggregateFunction.CUSTOM_RATIO
    );
  };

  const isValidMetric = (dto: {
    metric: string;
    customMetricRules: CustomMetricRule[];
    customConversionNames: Record<string, string>;
  }): boolean => {
    const ruleMaybe = dto.customMetricRules.find(
      (rule) => rule.metricName === dto.metric,
    );
    if (ruleMaybe) {
      return true;
    }
    const customConversionMaybe = dto.customConversionNames[dto.metric];
    if (customConversionMaybe) {
      return true;
    }
    const scoreMetricMaybe = SCORE_METRICS.find((m) => m.id === dto.metric);
    if (scoreMetricMaybe) {
      return true;
    }
    const miscMetricMaybe = MISC_METRICS.find((m) => m.id === dto.metric);
    if (miscMetricMaybe) {
      return true;
    }
    return [...META_METRICS, ...GOOGLE_METRICS, ...TIKTOK_METRICS].some(
      (m) => m.id === dto.metric,
    );
  };

  return {
    getMetaMetrics,
    getTiktokMetrics,
    metricToDisplayName,
    getValueFormatterType,
    getValueFormatter,
    getPercent,
    getPercentColor,
    getDefaultMetric,
    getMetricOptions,
    providerToMetricConfig,
    customMetricRulesToCustomMetricNames,
    getMappedCustomMetricRules,
    titleToMetricName,
    getProviderDefaultMetric,
    secondaryMetricsToPrimaryMetric,
    getAggregateFunction,
    getAvg,
    getScoreMetrics,
    getMiscMetrics,
    metricToTooltip,
    getProviderOfMetric,
    metricToProviderLogo,
    getRawMetricName,
    areMetricsOnSameScale,
    isValidMetric,
    isPlatformAgnosticMetric,
  };
};
